






































































































import { Component, Vue, PropSync, Inject } from 'vue-property-decorator';
import LibraryService from '@/services/library-service';
import { LibraryImportMessage, LibraryImportMessageType } from '@/models/library-import';
import "@/filters/helpers"
import { SnackbarOptions } from '@/models/form';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';


@Component
export default class ImportLibraryDialogComponent extends Vue {
  @Inject() LibraryService!: LibraryService;

  @PropSync('show', { default: false }) syncedShow!: boolean;

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  file = null as File | null;
  password = '';
  showPassword = false;
  loading = false;
  messages = [] as LibraryImportMessage[];

  formattedLibraryUpdateMessage = ''
  specialNotes = [] as string[];
  importRuleMessages = []as string[];
  importTemplateMessages = []as string[];

  activeTab: number|null = null;

  async importLibrary() {
    if (this.file === null) {
        return;
    }
    this.loading = true;

    try {
      const timeoutInMiliseconds = 1000 * 60 * 2; //2 minutes
      setTimeout(() => this.importTimedout(), timeoutInMiliseconds);

      this.messages = await this.LibraryService.uploadLibrary(this.file, this.password);

      if (this.loading === true) {
        this.file = null;
        this.password = '';

        this.populateMessages();
        this.selectActiveTab();
      }
    }
    catch (error) {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error);
    }
    finally {
      this.loading = false;
      this.$router.go(0);
    }
  }

  importTimedout() {
    if (this.loading === true) {
      this.formattedLibraryUpdateMessage = "The import process timed out."
      this.specialNotes.push("The import process timed out, but the file could still have been uploaded successfully and still be processing.");
      this.specialNotes.push("If the Library being imported is large in size, it may take a few minutes before it's loaded into the system.");
      this.specialNotes.push("If the changes imported do not appear in the system please contact Exis Support.");

      this.activeTab = 2;
      this.file = null;
      this.password = '';
      this.loading = false;
    }
  }

  close() {
    this.syncedShow = false;
    this.loading = false;
    this.file = null;
    this.password = '';
    this.messages = [];
    this.formattedLibraryUpdateMessage = ''
    this.specialNotes = [];
    this.importRuleMessages = [];
    this.importTemplateMessages = [];
    this.activeTab = null;
  }

  selectActiveTab() {
    if(this.importRuleMessages.length > 0)
      this.activeTab = 0;
    else if(this.importTemplateMessages.length > 0)
      this.activeTab = 1;
    else if(this.specialNotes.length > 0)
      this.activeTab = 2;
    else
      this.activeTab = 0;
  }

  populateMessages(){
    this.messages.sort(x => x.updateType).forEach(message => {
      const name = message.updateTargetName;
      switch(message.updateType){
        //special messages
        case LibraryImportMessageType.CustomNote:
          this.specialNotes.push(message.customMessage);
          break;
          //rule
        case LibraryImportMessageType.CreateRule:
          this.importRuleMessages.push(`${name} created`)
          break;
        case LibraryImportMessageType.DeleteRule:
          this.importRuleMessages.push(`${name} deleted`)
          break;
        case LibraryImportMessageType.UndeleteRule:
          this.importRuleMessages.push(`${name} restored`)
          break;
        case LibraryImportMessageType.UpdateRule:
          this.importRuleMessages.push(`${name} updated`)
          break;
        //case LibraryImportMessageType.RuleNotModified:
        //  this.importRuleMessages.push(`${name} not changed`)
        //  break;
          //template
        case LibraryImportMessageType.CreateTemplate:
          this.importTemplateMessages.push(`${name} created`)
          break;
        case LibraryImportMessageType.DeleteTemplate:
          this.importTemplateMessages.push(`${name} deleted`)
          break;
        case LibraryImportMessageType.UndeleteTemplate:
          this.importRuleMessages.push(`${name} restored`)
          break;
        case LibraryImportMessageType.UpdateTemplate:
          this.importTemplateMessages.push(`${name} updated`)
          break;
        //case LibraryImportMessageType.TemplateNotModified:
        //  this.importTemplateMessages.push(`${name} not changed`)
        //  break;
          //library
        case LibraryImportMessageType.CreateLibrary:
          this.formattedLibraryUpdateMessage = `Success! ${name} was created`;
          break;
        case LibraryImportMessageType.DeleteLibrary:
          this.formattedLibraryUpdateMessage = `Success! ${name} was deleted`;
          break;
        case LibraryImportMessageType.UpdateLibrary:
          this.formattedLibraryUpdateMessage =`Success! ${name} was updated`;
          break;
        case LibraryImportMessageType.LibraryNotModified:
          this.formattedLibraryUpdateMessage = `Success! no library parameters changed`;
          break;
      }
    });
  }
}
