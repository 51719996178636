

















































import {
  Component, Vue, Watch, Inject,
} from 'vue-property-decorator';

import Card from '@/components/material/Card.vue';
import LibraryTable from '@/components/library-maintenance/library-table.vue';
import { TableOptions, VDataTableOptions, SnackbarOptions } from '@/models/form';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';
import ImportLibraryDialog from '@/components/library-maintenance/import-library-dialog.vue';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';
import LocalState from '@/store/modules/local-state-module';
import { Library } from '@/models/library-maintenance.d';
import LibraryService from '@/services/library-service';
import debounce from 'lodash.debounce';
import axios, { CancelTokenSource } from 'axios';

const appState = getModule(AppState);
const localState = getModule(LocalState);

@Component({
  components: {
    Card,
    LibraryTable,
    EcSnackBar,
    ImportLibraryDialog,
  },
})
export default class LibrariesListView extends Vue {
  @Inject() LibraryService!: LibraryService;

  options: TableOptions = {
    link: true,
  }

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  items: Library[] = [];

  totalItems = 0;

  loading = false;

  page = 1;

  size = localState.defaultPageSize;

  sort: string[] = ['name,asc'];

  filter: string | null = null;

  showImportLibraryDialog = false;

  get color() { return appState.apiFault ? 'error' : 'primary'; }

  get apiFault() { return appState.apiFault; }

  get sortBy() { return this.sort.map((x) => x.split(',')[0]); }

  get sortDesc() { return this.sort.map((x) => x.split(',')[1] === 'desc'); }

  cancellationToken!: CancelTokenSource;

  @Watch('$route', { immediate: true })
  onRouteChange() {
    const {
      page = 1,
      size = localState.defaultPageSize,
      sort = ['name,asc'],
      filter,
    } = this.$route.query || {};

    this.page = +page;
    this.size = +size;
    this.sort = [sort].flat();
    this.filter = filter ? `${filter}` : null;

    this.loadPage();
  }

  private debounceFilter?: (arg: string) => void;

  onFilterChange(newFilter: string) {
    if (!this.debounceFilter) {
      this.debounceFilter = debounce((filter: string | null) => {
        const currentQuery = this.$route.query;
        const { filter: currentFilter } = currentQuery;

        if ((!currentFilter && !filter) || currentFilter === filter) return;

        const query = {
          ...currentQuery,
          page: '1',
          filter: filter || undefined,
        };

        this.$router.replace({
          query,
        });
      }, 500);
    }

    if (this.debounceFilter) {
      this.debounceFilter(newFilter);
    }
  }

  optionsUpdated(options: VDataTableOptions) {
    const {
      sortBy, sortDesc, page, itemsPerPage,
    } = options;
    const sort = sortBy.map((by, index) => `${by},${sortDesc[index] ? 'desc' : 'asc'}`);

    const currentQuery = this.$route.query;
    currentQuery.sort = [currentQuery.sort].flat();

    const newQuery = {
      page: `${page}`,
      size: `${itemsPerPage}`,
      sort,
      filter: this.filter,
    };
    if (sort.length === 0) {
      delete newQuery.sort;
    }

    if (JSON.stringify(currentQuery) === JSON.stringify(newQuery)) return;

    this.$router.push({
      query: newQuery,
    });
  }

  async loadPage() {
    this.loading = true;

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      if (this.cancellationToken)
        this.cancellationToken.cancel('Reloaded list');
      this.cancellationToken = axios.CancelToken.source();

      const list = await this.LibraryService.listLibraries(pagination, this.filter ?? undefined, this.cancellationToken.token);

      this.size = list.page.size;
      this.page = list.page.number;
      this.totalItems = list.page.totalElements;
      this.items = list._embedded.libraries;

      localState.setDefaultPageSize(this.size);
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error);
      }
    } finally {
      this.loading = false;
    }
  }

  async enableItem(item: Library, enable: boolean) {
    try {
      const text = enable
        ? 'If you enable this library, it is likely that all rules belonging to it will start to be processed and start generating hits.'
        : 'If you disable this library, any rule belonging to it will no longer be processed and will not generate hits.';

      appState.openDialog({
        title: enable ? 'Enable Library?' : 'Disable Library?',
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [{
          name: enable ? 'Enable' : 'Disable',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Changing library ...');
            this.LibraryService.setEnabledState(item.id, enable)
              .then(() => {
                const local = item;
                local.enabled = enable;

                this.snackbarOptions.value = false;
                const message = `Successfully ${enable ? 'enabled' : 'disabled'} library`;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions(message); });
              })
              .catch((error: Error) => {
                this.snackbarOptions.value = false;
                const message = `Failed to ${enable ? 'enable' : 'disable'} library: ${error.message}`;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(message); });
              })
            },
          }, {
            name: 'Cancel',
            color: 'primary',
            handler: () => Promise.resolve(false),
          }],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      const message = `Failed to ${enable ? 'enable' : 'disable'} library`;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(message); });
    }
  }

  async deleteItem(item: Library) {
    try {
      appState.openDialog({
        title: 'Delete Library?',
        text: '<p>Are you sure?</p><p>If you delete this library, any rule belonging to it will no longer be processed and will not generate hits.</p>',
        actions: [{
          name: 'Delete',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Deleting library ...');
            this.LibraryService.deleteLibrary(item.id)
              .then(() => {
                  this.snackbarOptions.value = false;
                  this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Deleted library'); });

                  this.loadPage();
                }
              )
              .catch((error: Error) => {
                  this.snackbarOptions.value = false;
                  this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete library: ${error.message}`); });
                }
              )
            }
          },
          {
            name: 'Cancel',
            color: 'primary',
            handler: () => Promise.resolve(false),
          }
        ]
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete library: ${error}`); });
    }
  }
}
